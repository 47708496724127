import { TEXT_MODEL, IMAGE_MODEL } from '../fabric';
const builder = ({
	agent,
	product,
	flight,
	flightPlan,
	posterId,
	posterKey,
}) => {
	let objArray = []
	let { avatar = '', name = '' } = agent
	objArray.push(
		{
			type: 'image',
			url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-23/pro-19-bg.jpg',
			css: {
				width: 360,
				height: 324,
				left: 180,
				top: 162,
				border: Object.assign({}, { ...IMAGE_MODEL.css.border })
			}
		},
		{
			type: 'image',
			url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-23/pro-19-bg-1.png',
			css: {
				width: 360,
				height: 507,
				left: 180,
				top: 133+ 253.5,
				border: Object.assign({}, { ...IMAGE_MODEL.css.border })
			}
		},
		avatar ? {
			type: 'image',
			url: 'http://' + avatar,
			css: {
				width: 28,
				height: 28,
				left: (28.0 / 2 + 35),
				top: (28.0 / 2 + (640 - 69 - 28)),
				border: Object.assign({}, { ...IMAGE_MODEL.css.border }, { radius: 28.0 / 2, color: '#ddd', width: 2 })
			}
		} : {},
		name ? {
			type: 'text',
			text: name,
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 17, color: '#333', fontWeight: 600, }),
				top: 25 + 10 + (640 - 69 - 28 -20),
				left: 60 + 15,
			},
			originX: 'left'
		} : {},
	)
	let { title = '', dep = '', des = '', agent_id = 0, id = 0, flight_no = '', flight_type = '', price_type, price_remark, min_price } = product
	objArray.push(

		{
			type: 'qrcode',
			content: 'http://agent-mall.efreight.cn/product/' + agent_id + '/' + id + '?posterId=' + posterId + '&posterKey=' + posterKey,
			css: {
				width: 86,
				height: 86,
				left: 241 + 86 / 2,
				top: 527 + 86 / 2,
				color: '#000',
				border: Object.assign({}, { ...IMAGE_MODEL.css.border })
			},
		},
		

		{
			type: 'text',
			text: "码上查看 | 最新优惠",
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 16, color: '#194077', fontWeight: 600, charSpacing: 100 }),
				top: 586,
				left: 35,
			},
			originX: 'left',
			originY: 'top',
		},
		{
			type: 'text',
			text: dep + ' - ' + des,
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 27, color: '#194077', fontWeight: 600, }),
				top: 390,
				left: 32,
			},
			originY: 'top',
			originX: 'right'
		},
		{
			type: 'text',
			text: title,
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 15, color: '#333', fontWeight: 600,}),
				top: 256,
				left: 32,
			},
			originX: 'right',
			originY: 'top'
		},
		price_type == 1 ? {
			type: 'text',
			text: '特\n惠\n￥',
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 13, color: '#194077', fontWeight: 600,}),
				top: 312,
				left: 32,
			},
			originX: 'left',
			originY: 'top'
		} : {},
		price_type == 1 ? {
			type: 'text',
			text: min_price +  "",
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 49, color: '#B04845', fontWeight: 600,}),
				top: 308,
				left: 52,
			},
			originX: 'left',
			originY: 'top'
		} : {},
		price_type == 1 ? {
			type: 'text',
			text: '编辑',
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 60, color: '#AD4847', fontWeight: 600,}),
				top: 328,
				left: 32,
			},
			originX: 'right',
			originY: 'top'
		} : {},
		price_type == 0 ? {
			type: 'text',
			text: price_remark,
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 53, color: '#AD4847', fontWeight: 600,}),
				top: 328,
				left: 32,
			},
			originX: 'right',
			originY: 'top'
		}: {}
	)
	if (flight) {
		var { flightModel = '', flightNo = '',  eta = '', etd = '' } = flight
		objArray.push(
			{
				type: 'text',
				text: '航班',
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 16, color: '#333', fontWeight: 500 }),
					top: 434,
					left: 32,
				},
				originX: 'left',
				originY: 'top',
			},
			{
				type: 'text',
				text: flight_no ? flight_no : flightNo,
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 17, color: '#333', fontWeight: 600, originX: 'left' }),
					top: 433,
					left: 71,
				},
				originX: 'left',
				originY: 'top',
			},
			{
				type: 'text',
				text: '机型',
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 16, color: '#333', fontWeight: 500 }),
					top: 468,
					left: 32,
				},
				originX: 'left',
				originY: 'top',
			},
			{
				type: 'text',
				text: flight_type ? flight_type : flightModel,
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 17, color: '#333', fontWeight: 600, originX: 'left' }),
					top: 468,
					left: 71,
				},
				originX: 'left',
				originY: 'top',
			},
			{
				type: 'text',
				text: 'ETD',
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 16, color: '#333', fontWeight: 500 }),
					top: 434,
					left: 246,
				},
				originX: 'left',
				originY: 'top',
			},
			{
				type: 'text',
				text: etd,
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 17, color: '#333', fontWeight: 600, originX: 'left' }),
					top: 433,
					left: 283,
				},
				originX: 'left',
				originY: 'top',
			},
			{
				type: 'text',
				text: 'ETA',
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 16, color: '#333', fontWeight: 500 }),
					top: 468,
					left: 246,
				},
				originX: 'left',
				originY: 'top',
			},
			{
				type: 'text',
				text: eta,
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 17, color: '#333', fontWeight: 600, originX: 'left' }),
					top: 467,
					left: 283,
				},
				originX: 'left',
				originY: 'top',
			},
		)
	}
	if(flightPlan) {
		objArray.push(
			{
				type: 'text',
				text: '每周' + flightPlan,
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 15, color: '#333', fontWeight: 600,}),
					top: 232,
					left: 32,
				},
				originX: 'right',
				originY: 'top'
			},
		)
	}
	return objArray
}

export { builder }
