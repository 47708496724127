import { TEXT_MODEL, IMAGE_MODEL } from '../fabric';
const builder = ({
	agent,
	product,
	flight,
	flightPlan,
	posterId,
	posterKey,
}) => {
	let objArray = []
	let { avatar = '', name = '' } = agent
	objArray.push(
		{
			type: 'image',
			url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-16/18-bg.jpg',
			css: {
				width: 360,
				height: 640,
				left: 180,
				top: 320,
				border: Object.assign({}, { ...IMAGE_MODEL.css.border })
			}
		},
		avatar ? {
			type: 'image',
			url: 'http://' + avatar,
			css: {
				width: 28,
				height: 28,
				left: (28.0 / 2 + 35),
				top: (28.0 / 2 + 35),
				border: Object.assign({}, { ...IMAGE_MODEL.css.border }, { radius: 28.0 / 2, color: '#ddd', width: 2 })
			}
		} : {},
		name ? {
			type: 'text',
			text: name,
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 12, color: '#fff', fontWeight: 600,}),
				top: 25 + 25,
				left: 70,
			},
			originX: 'left'
		} : {},
		{
			type: 'image',
			url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-16/18-bg-1.png',
			css: {
				width: 333,
				height: 605,
				left: 180,
				top: 320,
				border: Object.assign({}, { ...IMAGE_MODEL.css.border })
			}
		},
	)
	let { title = '', dep = '', des = '', agent_id = 0, id = 0, flight_no = '', flight_type = '' } = product
	objArray.push(
		{
			type: 'qrcode',
			content: 'http://agent-mall.efreight.cn/product/' + agent_id + '/' + id + '?posterId=' + posterId + '&posterKey=' + posterKey,
			css: {
				width: 86,
				height: 86,
				left: 180,
				top: 514,
				color: '#000',
				border: Object.assign({}, { ...IMAGE_MODEL.css.border })
			}
		},
		
		{
			type: 'image',
			url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-16/18-bg-2.png',
			css: {
				width: 219,
				height: 28,
				left: 180,
				top: 153 + 28 / 2,
				border: Object.assign({}, { ...IMAGE_MODEL.css.border })
			}
		},
		{
			type: 'text',
			text: title,
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 20, color: '#1578CC', fontWeight: 400, charSpacing: 100 }),
				top: 153 + 28 / 2,
				left: 180,
			}
		},

		{
			type: 'text',
			text: dep + ' - ' + des,
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 40, color: '#fff', fontWeight: 800, fontFamily: 'Klipan' }),
				top: 113,
				left: 180,
			}
		},
	)
	if (flight) {
		var { flightModel = '', flightNo = '', eta = '', etd = '' } = flight
		objArray.push(
			{
				type: 'text',
				text: '航班',
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 19, color: '#ffffff', fontWeight: 500 }),
					top: 208 + 19 / 2,
					left: 41 + 37 / 2,
				}
			},
			{
				type: 'text',
				text: flight_no ? flight_no : flightNo,
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 20, color: '#ffffff', fontWeight: 600 }),
					top: 208 + 19 / 2,
					left: 41 + 37 + 50,
				}
			},
			{
				type: 'text',
				text: '机型',
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 19, color: '#ffffff', fontWeight: 500 }),
					top: 242 + 19 / 2,
					left: 41 + 37 / 2,
				}
			},
			{
				type: 'text',
				text: flight_type ? flight_type : flightModel,
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 20, color: '#ffffff', fontWeight: 600 }),
					top: 242 + 19 / 2,
					left: 41 + 37 + 50,
				}
			},
			{
				type: 'text',
				text: 'ETD',
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 19, color: '#ffffff', fontWeight: 500 }),
					top: 208 + 19 / 2,
					left: 201 + 37 / 2,
				}
			},
			{
				type: 'text',
				text: etd,
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 20, color: '#ffffff', fontWeight: 600 }),
					top: 208 + 19 / 2,
					left: 201 + 37 + 50,
				}
			},
			{
				type: 'text',
				text: 'ETA',
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 19, color: '#ffffff', fontWeight: 500 }),
					top: 242 + 19 / 2,
					left: 201 + 37 / 2,
				}
			},
			{
				type: 'text',
				text: eta,
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 20, color: '#ffffff', fontWeight: 600 }),
					top: 242 + 19 / 2,
					left: 201 + 37 + 50,
				}
			},
			
		)
	}
	return objArray
}

export { builder }
