import { TEXT_MODEL, IMAGE_MODEL } from '../fabric';
const builder = ({
	agent,
	product,
	flight,
	flightPlan,
	posterId,
	posterKey,
}) => {
	let objArray = []
	let { avatar = '', name = '' } = agent
	objArray.push(
		{
			type: 'image',
			url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-16/17-bg.jpg',
			css: {
				width: 360,
				height: 640,
				left: 180,
				top: 320,
				border: Object.assign({}, { ...IMAGE_MODEL.css.border })
			}
		},
		avatar ? {
			type: 'image',
			url: 'http://' + avatar,
			css: {
				width: 28,
				height: 28,
				left: (28.0 / 2 + 22),
				top: (28.0 / 2 + 20),
				border: Object.assign({}, { ...IMAGE_MODEL.css.border }, { radius: 28.0 / 2, color: '#ddd', width: 2 })
			}
		} : {},
		name ? {
			type: 'text',
			text: name,
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 12, color: '#fff', fontWeight: 600, }),
				top: 25 + 10,
				left: 60,
			},
			originX: 'left'
		} : {},
	)
	let { title = '', dep = '', des = '', agent_id = 0, id = 0, flight_no = '', flight_type = '' } = product
	objArray.push(
		{
			type: 'image',
			url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-16/17-bg-2.png',
			css: {
				width: 86,
				height: 22,
				left: 33 + 86/2,
				top: 597 + 22 / 2,
				border: Object.assign({}, { ...IMAGE_MODEL.css.border })
			}
		},
		{
			type: 'text',
			text: '码上查看',
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 11, color: '#fff', fontWeight: 400, fontFamily: '联盟起艺卢帅正锐黑体', fontStyle: 'italic' }),
				top: 607,
				left: 76,
			}
		},
		{
			type: 'qrcode',
			content: 'http://agent-mall.efreight.cn/product/' + agent_id + '/' + id + '?posterId=' + posterId + '&posterKey=' + posterKey,
			css: {
				width: 86,
				height: 86,
				left: 33 + 86/2,
				top: 500 + 86/2,
				color: '#000',
				border: Object.assign({}, { ...IMAGE_MODEL.css.border })
			}
		},
		

		{
			type: 'text',
			text: title,
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 31, color: '#fff', fontWeight: 400, fontFamily: '联盟起艺卢帅正锐黑体', charSpacing: 300 }),
				top: 96 + 68 / 2,
				left: 180,
			}
		},
		{
			type: 'image',
			url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-16/17-bg-3.png',
			css: {
				width: 114,
				height: 30,
				left: 180,
				top: 150 + 30 / 2 + 10,
				border: Object.assign({}, { ...IMAGE_MODEL.css.border })
			}
		},
		{
			type: 'text',
			text: dep + ' - ' + des,
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 16, color: '#003571', fontWeight: 800 }),
				top: 150 + 30 / 2 + 10,
				left: 180,
			}
		},
		{
			type: 'image',
			url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-9/pro-poster15-plane.png',
			css: {
				width: 26,
				height: 25,
				left: 180,
				top: 350,
				border: Object.assign({}, { ...IMAGE_MODEL.css.border })
			}
		},
	)
	if (flight) {
		var { flightModel = '', flightNo = ''} = flight
		objArray.push(
			{
				type: 'image',
				url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-16/17-bg-1.png',
				css: {
					width: 223,
					height: 30,
					left: 180,
					top: 217 + 30 / 2,
					border: Object.assign({}, { ...IMAGE_MODEL.css.border })
				}
			},
			{
				type: 'text',
				text: '航班',
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 19, color: '#ffffff', fontWeight: 500 }),
					top: 217 + 30 / 2,
					left: 52 + 20 + 44,
				}
			},
			{
				type: 'text',
				text: flight_no ? flight_no : flightNo,
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 18, color: '#333', fontWeight: 500, originX: 'left' }),
					top: 217 + 30 / 2,
					left: 72 + 80 + 44,
				}
			},
			{
				type: 'image',
				url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-16/17-bg-1.png',
				css: {
					width: 223,
					height: 30,
					left: 180,
					top: 256 + 30 / 2,
					border: Object.assign({}, { ...IMAGE_MODEL.css.border })
				}
			},
			{
				type: 'text',
				text: '机型',
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 19, color: '#ffffff', fontWeight: 500 }),
					top: 256 + 30 / 2,
					left: 52 + 20 + 44,
				}
			},
			{
				type: 'text',
				text: flight_type ? flight_type : flightModel,
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 18, color: '#333', fontWeight: 500, originX: 'left' }),
					top: 256 + 30 / 2,
					left: 72 + 80 + 44,
				}
			},
			{
				type: 'image',
				url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-16/17-bg-1.png',
				css: {
					width: 223,
					height: 30,
					left: 180,
					top: 296 + 30 / 2,
					border: Object.assign({}, { ...IMAGE_MODEL.css.border })
				}
			},
			{
				type: 'text',
				text: '班期',
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 19, color: '#ffffff', fontWeight: 500 }),
					top: 296 + 30 / 2,
					left: 52 + 20 + 44,
				}
			},
			{
				type: 'text',
				text: '每周' + flightPlan,
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 18, color: '#333', fontWeight: 500, originX: 'left' }),
					top: 296 + 30 / 2,
					left: 72 + 80 + 44,
				}
			},
		)
	}
	return objArray
}

export { builder }
