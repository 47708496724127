//
//
//
//
//
//
//

/* eslint-disable */
import axios from 'axios';
import Painter from '@/components/painter5';
import * as BUILDER from '@/utils/product-builder';
export default {
	metaInfo () {
      return {
			title: '海报制作',
		}
	},
	components: {
		Painter,
	},
	data: ()=> ({
		fabricObjectArray: [],
		poster_id: 0,
		shop_id: 0,
		shopInfo: null,
		id: null,
        appId: '',
        product_id:0,
        productInfo:null,
		price_type:'',
		posterKey: '',
		flightPlan: '',
		flightInfo: null,
	}),
	created: async function() {
		this.posterKey = new Number(new Date().getTime()).toString()
		let _this = this
		let {shop_id = 0, product_id = 0, poster_id = 0} = this.$route.params,
			{appId = 'wx60ced1c8de7908d5'} = this.$route.query
		if(shop_id) {
			this.shop_id =  shop_id
		}
		if(poster_id) {
			this.poster_id = poster_id
		}
		if(appId) {
			this.appId = appId
        }
        if(product_id) {
			this.product_id = product_id
        }
	},
	mounted: async function() {

		if(this.shop_id) {
			await this.loadShopInfo()
        }
        if(this.product_id) {
			await this.loadProductInfo()
		}
		if(this.poster_id) {
			this.getObjArrayFromBulider()
		}
	},
	methods: {
        getRequest: function(url){
			return new Promise((resolve, reject)=> {
				axios.get(url)
				.then(res=> {
					resolve(res)
				}).catch(err=> {
					console.log(err)
				})
			})
        },
        _getMinPriceFromProduct(p) {
			let min = 9999.00
			if(p.price_type == 1) {
				let {q1 = 0, q2 = 0, q3 = 0, q4 = 0, q5 = 0} = p
				q1 = parseFloat(q1)
				q2 = parseFloat(q2)
				q3 = parseFloat(q3)
				q4 = parseFloat(q4)
				q5 = parseFloat(q5)
				if (!!q5 && q5 < min) {
					min = q5
				}
				if (!!q4 && q4 < min) {
					min = q4
				}
				if (!!q3 && q3 < min) {
					min = q3
				}
				if (!!q2 && q2 < min) {
					min = q2
				}
				if (!!q1 && q1 < min) {
					min = q1
				}
				return min
			}

		},
		loadShopInfo: async function(){
			let url='/api-mall/agent/' + this.shop_id,
				res = await this.getRequest(url)
			if(!!res){
				this.shopInfo = res.data
			}
		},
        loadProductInfo: async function(){
			let url='/api-mall/product/' + this.product_id,
				res = await this.getRequest(url)
			if(!!res){
				this.productInfo = res.data
				this.minPrice=this._getMinPriceFromProduct(this.productInfo)
				if(this.productInfo.type==1){
					this.flightPlan=!!this.productInfo.flight_plan ? this.productInfo.flight_plan.split('').join('/') : ''
				}else{
					await this.loadFlightInfo()
				}
			}
        },
        loadFlightInfo: async function(){
			let url='/api-mall/proxy/yctop/flights/list?freightRoute=' + this.productInfo.route+'&productId='+this.productInfo.id_at_yctop,
				res = await this.getRequest(url)
			if(!!res){
				!!res.data.data && res.data.data.first && res.data.data.first.length>0 && ( this.flightInfo=res.data.data.first[0] )
				let flight_plan=!!res.data.data && res.data.data.first && res.data.data.first.length>0?res.data.data.first[0].flightPlan:''
				this.flightPlan=!!flight_plan ? flight_plan.split('').join('/'):''
			}
		},
		getObjArrayFromBulider() {
			let builder = BUILDER['builderForProductPoster' + this.poster_id]
			let array = builder({
				agent: this.shopInfo,
				product: this.productInfo,
				flight: this.flightInfo,
				flightPlan: this.flightPlan,
				posterId: this.poster_id,
				minPrice:  this.productInfo.price_type==0 ? '单询' : '' + this.minPrice,
				posterKey: this.posterKey,
			})
			this.fabricObjectArray = array
		},


	}
}
