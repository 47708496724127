import { TEXT_MODEL, IMAGE_MODEL } from './fabric';

const builderForProductPoster15 = ({
	agent,
	product,
	flight,
	flightPlan,
	posterId,
	posterKey,
}) => {
	let objArray = []
	let { avatar = '', name = '' } = agent
	objArray.push(
		{
			type: 'image',
			url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-8/pro-poster15-bg.jpg',
			css: {
				width: 360,
				height: 640,
				left: 180,
				top: 320,
				border: Object.assign({}, { ...IMAGE_MODEL.css.border })
			}
		},
		avatar ? {
			type: 'image',
			url: 'http://' + avatar,
			css: {
				width: 28,
				height: 28,
				left: (28.0 / 2 + 124),
				top: (28.0 / 2 + 28),
				border: Object.assign({}, { ...IMAGE_MODEL.css.border }, { radius: 28.0 / 2, color: '#ddd', width: 2 })
			}
		} : {} ,
		name ? {
			type: 'text',
			text: name,
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 12, color: '#fff', fontWeight: 600 }),
				top: 33 + 10,
				left: 190,
			}
		} : {},
		{
			type: 'image',
			url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-8/pro-poster15-2.png',
			css: {
				width: 276,
				height: 144,
				left: 180,
				top: 217,
				border: Object.assign({}, { ...IMAGE_MODEL.css.border })
			}
		},
		{
			type: 'image',
			url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-8/pro-poster15-1.png',
			css: {
				width: 276,
				height: 144,
				left: 180,
				top: 330,
				border: Object.assign({}, { ...IMAGE_MODEL.css.border })
			}
		},
	)
	let { title = '', dep = '', des = '', agent_id = 0, id = 0, flight_no = '', flight_type = '' } = product
	objArray.push(
		{
			type: 'qrcode',
			content: 'http://agent-mall.efreight.cn/product/' + agent_id + '/' + id + '?posterId=' + posterId + '&posterKey=' + posterKey,
			css: {
				width: 93,
				height: 93,
				left: 260,
				top: 462,
				color: '#000',
				border: Object.assign({}, { ...IMAGE_MODEL.css.border })
			}
		},
		{
			type: 'text',
			text: title,
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 24, color: '#fff', fontWeight: 400, fontFamily: '联盟起艺卢帅正锐黑体' }),
				top: 281,
				left: 180,
			}
		},
		{
			type: 'text',
			text: dep,
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 29, color: '#333', fontWeight: 800 }),
				top: 342,
				left: 120,
			}
		},
		{
			type: 'text',
			text: des,
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 29, color: '#333', fontWeight: 800 }),
				top: 342,
				left: 240,
			}
		},
		{
			type: 'image',
			url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-9/pro-poster15-plane.png',
			css: {
				width: 26,
				height: 25,
				left: 180,
				top: 350,
				border: Object.assign({}, { ...IMAGE_MODEL.css.border })
			}
		},
	)
	if (flight) {
		var { flightModel = '', flightNo = '', eta = '', etd = '' } = flight
		objArray.push(
			{
				type: 'text',
				text: etd,
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 16, color: '#666', fontWeight: 500 }),
					top: 366,
					left: 117,
				}
			},
			{
				type: 'text',
				text: eta,
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 16, color: '#666', fontWeight: 400 }),
					top: 366,
					left: 243,
				}
			},
		)
	}
	objArray.push(
		{
			type: 'text',
			text: '航班',
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 19, color: '#2554A4', fontWeight: 500 }),
				top: 431,
				left: 68,
			}
		},
		{
			type: 'text',
			text: flight_no ? flight_no : flightNo,
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 20, color: '#333', fontWeight: 500, originX: 'left' }),
				top: 431,
				left: 99,
			}
		},
		{
			type: 'text',
			text: '机型',
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 19, color: '#2554A4', fontWeight: 500 }),
				top: 464,
				left: 68,
			}
		},
		{
			type: 'text',
			text: flight_type ? flight_type : flightModel,
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 20, color: '#333', fontWeight: 500, originX: 'left' }),
				top: 464,
				left: 99,
			}
		},

		{
			type: 'text',
			text: '班期',
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 19, color: '#2554A4', fontWeight: 500 }),
				top: 495,
				left: 68,
			}
		},
		{
			type: 'text',
			text: '每周' + flightPlan,
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 20, color: '#333', fontWeight: 500, originX: 'left' }),
				top: 495,
				left: 99,
			}
		},
		{
			type: 'text',
			text: '--盛大开航--',
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 15, color: '#333', fontWeight: 400 }),
				top: 125,
				left: 180,
			}
		},
		{
			type: 'text',
			text: '可自行编辑内容',
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 15, color: '#fff', fontWeight: 400 }),
				top: 561,
				left: 180,
			}
		},
		{
			type: 'text',
			text: '可编辑',
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 15, color: '#fff', fontWeight: 400 }),
				top: 581,
				left: 180,
			}
		},
	)

	return objArray
}
const builderForProductPoster16 = ({
	agent,
	product,
	flight,
	flightPlan,
	posterId,
	posterKey,
	minPrice,
}) => {
	let objArray = []
	let { avatar = '', name = '' } = agent
	objArray.push(
		{
			type: 'image',
			url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-9/pro-poster16-bg.png',
			css: {
				width: 360,
				height: 640,
				left: 180,
				top: 320,
				border: Object.assign({}, { ...IMAGE_MODEL.css.border })
			}
		},
		avatar ? {
			type: 'image',
			url: 'http://' + avatar,
			css: {
				width: 28,
				height: 28,
				left: (28.0 / 2 + 22),
				top: (28.0 / 2 + 20),
				border: Object.assign({}, { ...IMAGE_MODEL.css.border }, { radius: 28.0 / 2, color: '#ddd', width: 2 })
			}
		} : {},
		name ? {
			type: 'text',
			text: name,
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 12, color: '#fff', fontWeight: 600, originX: 'left' }),
				top: 25 + 10,
				left: 60,
			}
		} : {},
		{
			type: 'image',
			url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-9/pro-poster16-1.png',
			css: {
				width: 13,
				height: 39,
				left: 26,
				top: 576,
				border: Object.assign({}, { ...IMAGE_MODEL.css.border })
			}
		},
		{
			type: 'image',
			url: ' https://doc.efreight.cn/weapp/zjhy/static/2021-4-9/pro-poster16-2.png',
			css: {
				width: 143,
				height: 25,
				left: 91,
				top: 415,
				border: Object.assign({}, { ...IMAGE_MODEL.css.border })
			}
		},
		{
			type: 'image',
			url: 'https://doc.efreight.cn/weapp/zjhy/static/2021-4-9/pro-poster16-3.png',
			css: {
				width: 323,
				height: 79,
				left: 180,
				top: 473,
				border: Object.assign({}, { ...IMAGE_MODEL.css.border })
			}
		},

	)
	let { title = '', dep = '', des = '', agent_id = 0, id = 0, flight_no = '', flight_type = '' } = product
	objArray.push(
		{
			type: 'qrcode',
			content: 'http://agent-mall.efreight.cn/product/' + agent_id + '/' + id + '?posterId=' + posterId + '&posterKey=' + posterKey,
			css: {
				width: 73,
				height: 73,
				left: 296,
				top: 573,
				color: '#000',
				border: Object.assign({}, { ...IMAGE_MODEL.css.border })
			}
		},
		{
			type: 'text',
			text: title,
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 24, color: '#fff', fontWeight: 400, fontFamily: '联盟起艺卢帅正锐黑体', charSpacing: 100 }),
				top: 139,
				left: 180,
			}
		},
		{
			type: 'text',
			text: dep + ' - ' + des,
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 18, color: '#00044C', fontWeight: 500, originX: 'left' }),
				top: 415,
				left: 47,
			}
		},
		{
			type: 'text',
			text: minPrice,
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 49, color: '#F1CF43', fontWeight: 500, originX: 'left' }),
				top: 575,
				left: 40,
			}
		},
	)
	if (flight) {
		var { flightModel = '', flightNo = '', eta = '', etd = '' } = flight
		objArray.push(
			{
				type: 'text',
				text: etd,
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 16, color: '#fff', fontWeight: 400, originX: 'left' }),
					top: 457,
					left: 270,
				}
			},
			{
				type: 'text',
				text: eta,
				css: {
					border: Object.assign({}, { ...TEXT_MODEL.css.border }),
					text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 16, color: '#fff', fontWeight: 400, originX: 'left' }),
					top: 490,
					left: 270,
				}
			},
		)
	}
	objArray.push(
		{
			type: 'text',
			text: flight_no ? flight_no : flightNo,
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 16, color: '#fff', fontWeight: 400, originX: 'left' }),
				top: 457,
				left: 87,
			}
		},
		{
			type: 'text',
			text: flight_type ? flight_type : flightModel,
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 16, color: '#fff', fontWeight: 400, originX: 'left' }),
				top: 490,
				left: 87,
			}
		},
		{
			type: 'text',
			text: '班期',
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 17, color: '#fff', fontWeight: 500, originX: 'left' }),
				top: 564,
				left: 163,
			}
		},
		{
			type: 'text',
			text: '每周' + flightPlan,
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, { fontSize: 17, color: '#fff', fontWeight: 500, originX: 'left' }),
				top: 588,
				left: 163,
			}
		},
		{
			type: 'text',
			text: '—— 可自行编辑内容 ——',
			css: {
				border: Object.assign({}, { ...TEXT_MODEL.css.border }),
				text: Object.assign({}, { ...TEXT_MODEL.css.text }, {
					fontSize: 16, color: '#F1CF43', fontWeight: 500,
					shadow: '0px 1px 3px rgba(0, 0, 0, 0.5)'
				}),
				top: 182,
				left: 180,
			}
		},
	)

	return objArray
}

import {builder as builderForProductPoster17 } from './product-builder/17';
import {builder as builderForProductPoster18 } from './product-builder/18';
import {builder as builderForProductPoster19 } from './product-builder/19';
import {builder as builderForProductPoster20 } from './product-builder/20';

export {
	builderForProductPoster15,
	builderForProductPoster16,
	builderForProductPoster17,
	builderForProductPoster18,
	builderForProductPoster19,
	builderForProductPoster20,
}